import React from 'react'

import { HeroSection, HeroWrapper, LeftHero, SubtitleHero, PHero, H1Hero, ButtonHero, RightHero, ButtonMotion } from './HeroStyled'

import ImageHero from "../imagehero.js"

import { BsFillTelephoneFill } from "react-icons/bs";
import { IconContext } from "react-icons";

 
const Hero = () => {
  return ( 
  <>

  <HeroSection>
    <HeroWrapper>
        <LeftHero>

          <SubtitleHero initial={{opacity: 0}} animate={{opacity: 1}} transition={{ delay: 0.15, duration:0.55}}> Czas dla Ciebie i Twoich zwierząt </SubtitleHero>
        
    
          <H1Hero initial={{opacity: 0}} animate={{opacity: 1}} transition={{ delay: 0.25, duration:0.55}}>Profesjonalne usługi weterynaryjne</H1Hero>

          <PHero 
           initial={{opacity: 0}} animate={{opacity:1}} transition={{delay: 0.35, duration: 0.55}}>Specjalistyczne zabiegi dla psów, kotów, zwierząt futerkowych i gryzoni
          </PHero>

          <ButtonMotion initial={{opacity: 0}} animate={{opacity:1}} transition={{delay: 0.45, duration: 0.55}}>

         <ButtonHero href="tel:+48608345265"> 
          <IconContext.Provider value={{ style: { color: 'f3f3f3', verticalAlign: 'middle', width:'26', height:'26', marginRight:'10px', purpose: 'any maskable'}}}>
          <BsFillTelephoneFill value={{ className:'react-icons-button'}}  />UMÓW WIZYTĘ
          </IconContext.Provider> 
          </ButtonHero> 

         </ButtonMotion> 

        </LeftHero>
            

        <RightHero>
    
             <ImageHero src="image.png" alt="weterynarz Kalisz"/>
    
        </RightHero>

    </HeroWrapper>  
    </HeroSection>
   
    </>  

  )
}  
export default Hero
