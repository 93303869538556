import React from 'react'
import { UslugiSection, UslugiWrapper, BigTitle, FlexWrapper, BoxImage, UslugiList, TextUslugi, H3Uslugi } from './UslugiStyled'
import { StaticImage }from "gatsby-plugin-image"

import ImageUslugi1 from '../imageuslugi1.js'




const Uslugi = () => {
  
  return ( 
    <>

    <UslugiSection>
    <UslugiWrapper id="uslugi">
         <BigTitle
         whileInView={{y: 0, opacity:1}}
         initial={{ y:100, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >Usługi</BigTitle>
        
         
            
            <FlexWrapper>
          
             <BoxImage
             whileInView={{x: 0, opacity:1}}
             initial={{ x:-20, opacity:0}}
             transition={{delay:0.25, duration: 1.25}}
             >  
            <ImageUslugi1 />
            </BoxImage>
            <UslugiList>
            <H3Uslugi>Świadczymy usługi weterynaryjne:</H3Uslugi>


            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz Kalisz profilaktyka"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/profilaktyka">Profilaktyka
               
               </TextUslugi>
            </li>
            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz chirurg Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/chirurgia">Chirurgia</TextUslugi>
            </li>
            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz radiolog Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/radiologia">Radiologia</TextUslugi>
            </li>

            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="USG weterynarz Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/usg">USG</TextUslugi>
            </li>

            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz Kalisz internista"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/interna">Interna</TextUslugi>
            </li>

            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz kardiolog Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/kardiologia">Kardiologia</TextUslugi>
               
            </li>

           <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Dietetyka zwierząt Kalisz weterynarz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/dietetyka">Dietetyka weterynaryjna</TextUslugi>
            </li>
            
            <li>
                <span>

                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz Kalisz dermatolog zierząt"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/dermatologia">Dermatologia</TextUslugi>
            </li>
            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz stomalog Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/stomatologia">Stomatologia</TextUslugi>
            </li>


            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Rozród zwierząt weterynarz Kalisz"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/poloznictwo-i-rozrod">Położnictwo i rozród</TextUslugi>
            </li>
            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz kalisz gryzonie"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/choroby-krolikow-gryzoni">Choroby gryzoni</TextUslugi>
            </li>
            
            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz Kalisz laboratorium"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/laboratorium">Laboratorium weterynaryjne</TextUslugi>
            </li>


            <li>
                <span>
                <StaticImage
    src="./iconuslugi.png"
    alt="Weterynarz Kalisz paszporty, czipowanie"
    width={36}
    height={36}
    placeholder="blurred"
    />
                </span>
               <TextUslugi to="/paszporty-i-czipowanie">Paszporty i czipowanie</TextUslugi>
            </li>

        
            </UslugiList>
             </FlexWrapper>
    </UslugiWrapper>
    </UslugiSection>
    </>
  )
}

    
export default Uslugi
