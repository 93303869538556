import React from 'react'
import { OnasWrapper, OnasSection,  BigTitle,BoxTextFull,BoxImageFull,TextOnasFull, BoxOnas, BoxImage, BoxText, H3Onas, TextOnas, } from './OnasStyled'

import ImageOnas1 from '../imageonas1'
import ImageOnas2 from '../imageonas2'
import ImageOnas3 from '../imageonas3'


const Onas = () => {
  
  return ( 
  <OnasSection>
    <OnasWrapper id="o-nas">
         <BigTitle
         whileInView={{y:0, opacity:1}}
         initial={{ y:100, opacity:0}}
         transition={{delay:0.25, duration: 0.75}}
         >O nas
         </BigTitle>  
           <BoxTextFull>
                 <H3Onas>Jesteśmy z Wami od 27 lat</H3Onas>
                 <TextOnasFull>Lecznica dla Zwierząt została założona przez 
                     lekarza weterynarii Sławomira Witczaka w 1995 roku. Od początku działalności 
                     wprowadzamy udoskonalenia i innowacyjności w Naszej pracy, korzystając z 
                     osiągnięć nowoczesnych technik jak również 
                     doniesień naukowych, zapewniając kompleksową opiekę weterynaryjną.</TextOnasFull>
            </BoxTextFull>
         <BoxImageFull
            whileInView={{x: 0, opacity:1}}
            initial={{ y:20, opacity:0}}
            transition={{delay:0.25, duration: 1.25}}
            >  
    
      
           <ImageOnas1 /> 
           
              </BoxImageFull>
        <BoxOnas>
         

            <BoxText>
                 <H3Onas>Z miłości dla zwierząt </H3Onas>
                 <TextOnas>Naszą misją jest niesienie pomocy chorym zwierzętom, 
                     a także profilaktyka w zakresie chorób zwierząt. Otaczamy je najlepszą opieką, 
                     a szczegółowa diagnostyka doświadczonych weterynarzy przyśpiesza rozpoznanie i 
                     ułatwia wprowadzenie skutecznej terapii.</TextOnas>
            </BoxText>
            <BoxImage 
            whileInView={{x:0, opacity:1}}
            initial={{ x:20, opacity:0}}
            transition={{delay:0.25, duration: 1.25}}
            >
           <ImageOnas2 />
            </BoxImage>
            

        </BoxOnas>
     
          

   
        <BoxOnas>
            <BoxImage
            whileInView={{x: 0, opacity:1}}
            initial={{ x:-20, opacity:0}}
            transition={{delay:0.25, duration: 1.25}}
            >
                 <ImageOnas3 />
            </BoxImage>

            <BoxText>
                <H3Onas>Wysoka jakość usług</H3Onas>
                <TextOnas>Wyróżnia Nas wysoka jakość i zaangażowanie, 
                    nowoczesne wyposażenie, weterynaryjne laboratorium diagnostyczne, wykwalifikowany personel, składający się 
                    z dyplomowanych lekarzy weterynarii, jak również szeroki wachlarz usług. 
                </TextOnas>
            </BoxText> 
               
        </BoxOnas>

    </OnasWrapper>
</OnasSection>
);

  }
export default Onas
