import React from "react"
import { ThemeProvider } from "styled-components";
import { theme } from "../components/theme.js"

import "../components/layout.css"

import SEO from "../components/seo"

import Hero from "../components/Hero/Hero"
import Navbar from "../components/Navbar/Navbar"
import Onas from "../components/Onas/Onas"
import Uslugi from "../components/Uslugi/Uslugi"
import Footer from "../components/Footer/Footer"

const Index = () => {
    return ( 
 <> 
  <ThemeProvider theme={theme}>
     <SEO 
     title="Weterynarz Kalisz" 
     description="Oferujemy szeroki wachlarz usług weterynaryjnych obejmujący: profilaktykę, diagnostykę i leczenie psów, kotów, zwierząt futerkowych i gryzoni. 
     Jeżeli szukasz specjalisty lekarza weterynarii w Kaliszu - zapraszamy do Lecznicy dla Zwierząt znajdującej się w Kaliszu przy ul. Lipowej 17-21." 
     keywords=" weterynarz, kalisz, weterynaria, weterynarz kalisz, pogotowie weterynaryjne, 24h"/>

 <Navbar />
 <Hero />
 <Onas  />
 <Uslugi />
 <Footer />
 </ThemeProvider>
 </>
)
  }

export default Index