import styled from "styled-components"
import { motion } from "framer-motion"

export const OnasSection = styled.div`
width:100% ;
background-color: ${({ theme }) => theme.colors.white};
`;


export const OnasWrapper = styled.div`
width: 100%;
max-width: 1534px;
background-color: ${({ theme }) => theme.colors.white};
margin: 0 auto;
padding-left: 5%;
padding-right: 5%;
scroll-margin: 100px 0 0 80px;


@media screen and (max-width: 768px) {
padding-left: 15px;
padding-right: 15px;
}
`;


export const BoxOnas = styled.div`
display: flex;


@media screen and (max-width: 768px) {
display: flex;
flex-direction: column;
}
`;

export const BigTitle = styled(motion.h2)`
font-family: 'Montserrat', sans-serif;
font-size: clamp(5rem, calc(5rem + ((1vw - 0.36rem) * 7.1429)), 11rem);
min-height: 0vw;
display:flex;
justify-content: start;
color: #6aa940;
font-weight: 900;
text-shadow: 3px 5px 5px rgba(118, 128, 112, 1);

@media screen and (max-width: 768px) {
  font-family: 'Montserrat', sans-serif;
font-size:clamp(2.5rem, calc(2.5rem + ((1vw - 0.36rem) * 10.119)), 11rem);
  min-height: 0vw;
display:flex;
justify-content: start;
padding-top:10px;
margin:0;
color:${({ theme }) => theme.colors.lightGreen};
font-weight: 900;
text-shadow: 2px 2px 2px rgba(118, 128, 112, 1);
}
`;

export const BoxImage = styled(motion.div)`
width:50%;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
align-content: center;
overflow: auto;
flex-direction: row;

@media screen and (max-width: 768px) {
  width:100%;
  padding-bottom:20px; 
}

img {
margin-top:20px;
border-radius: 10px; 
}
`;


export const BoxText = styled.div`
width: 50%;

display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: row;
    padding-left:25px;
    padding-right:25px;

@media screen and (max-width: 768px) {
  width:100%;
}

@media (max-width: 1024px) {
  padding-left:15px;
  padding-right:15px ;
}
`;

export const H3Onas = styled.h3`
font-family: 'Montserrat', sans-serif;
font-size:clamp(1.2rem, calc(1.2rem + ((1vw - 0.36rem) * 0.7143)), 1.8rem);
min-height: 0vw;
line-height: 2rem;
font-weight: 900;
color:#6aa940;
text-shadow: 0px 1px 2px rgba( 0, 0, 0, 0.25);

@media screen and (max-width: 768px) {
padding-top:20px;
line-height: 0.8rem;
}

@media screen and (max-width: 1024px) {
margin-bottom:5px;
}
`;

export const TextOnas= styled.p`
font-family: 'Montserrat', sans-serif;
font-size: clamp(0.9rem, calc(0.9rem + ((1vw - 0.36rem) * 0.2381)), 1.1rem);
min-height: 0vw;
line-height: 1.8rem;
font-weight: 500;
color:#4F4A4A;
padding-top:10px;

@media screen and (max-width: 768px) {
line-height: 1.7rem; 
}

@media screen and (max-width: 1024px) {
line-height: 1.5rem; 

}
`;

export const BoxTextFull = styled.div`
width: 100%;

display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: auto;
    flex-direction: row;
    padding-left:25px;
    padding-right:25px;


@media screen and (max-width: 768px) {
  width:100%;
>p {
  margin-bottom: 0px;
  
}


}

@media (max-width: 1024px) {
  padding-left:15px;
  padding-right:15px ;
}
`;


export const BoxImageFull = styled(motion.div)`
width:100%;
display: flex;
flex-wrap: wrap;
justify-content: center;
align-items: center;
align-content: center;
overflow: auto;
flex-direction: row;
margin-bottom:50px;

img {
border-radius: 10px; 
}

@media screen and (max-width: 768px) {
  width:100%;
margin-bottom: 20px;
}
`;



export const TextOnasFull = styled.p`

font-size: clamp(0.9rem, calc(0.9rem + ((1vw - 0.36rem) * 0.2381)), 1.1rem);
min-height: 0vw;
line-height: 1.8rem;
font-weight: 500;
color:#4F4A4A;
padding-top:10px;

@media screen and (max-width: 768px) {
line-height: 1.7rem; 
}

@media screen and (max-width: 1024px) {
line-height: 1.5rem; 

}
`;
