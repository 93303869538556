import styled from "styled-components";
import { motion } from "framer-motion"

export const HeroSection = styled.div`
width:100% ;
background-color: ${({ theme }) => theme.colors.lightGrey};
`;


export const HeroWrapper = styled.section`
width:100%;
max-width: 1534px;
height: 90vh;
display: flex;
flex-wrap: nowrap;
justify-content: center;
align-items: center;
align-content: center;
overflow: auto;
flex-direction: row;
background-color: ${({ theme }) => theme.colors.lightGrey};
margin: 0 auto;
padding-left:5%;
padding-right:5%;


@media screen and (max-width: 768px) {
flex-direction:column-reverse;
align-content:flex-start ;
justify-content: start ;
padding-top:10px;
padding-left:15px;
padding-right:15px;
overflow: hidden;
}

@media screen and (max-width: 1024px) {
width:100%; 
height: 100%;
padding-bottom:100px;
}

@media screen and (max-width: 457px) {
width:100%; 
height: 90vh;
}
`;

export const LeftHero = styled.div`
width: 50%;



@media screen and (max-width: 1024px) {
width:100% ;
height: 100%;
}

@media screen and (max-width: 360px) {
width:100% ;
}
`;


export const SubtitleHero = styled(motion.p)`
font-size:clamp(1rem, calc(1.2rem + ((1vw - 0.36rem) * 0.3571)), 1.5rem);
min-height: 0vw;
color:  ${({ theme }) => theme.colors.lightGreen};
font-weight: ${({ theme }) => theme.fontWeight.fontWeightBold};
text-shadow: 0px 1px 1px rgba( 0, 0, 0, 0.25);
`;


export const H1Hero = styled(motion.h1)`
font-size: clamp(2rem, calc(2rem + ((1vw - 0.36rem) * 2.6786)), 4.25rem);
min-height: 0vw;
font-weight:${({ theme }) => theme.fontWeight.fontWeightBold};
color: ${({ theme }) => theme.colors.midleBlack};
text-shadow: 0px 4px 4px rgba( 0, 0, 0, 0.35);
`;


export const PHero = styled(motion.h2)`
font-size: clamp(1rem, calc(1rem + ((1vw - 0.225rem) * 0.5128)), 1.5rem);
min-height: 0vw;
line-height: 2rem;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightMedium};
color:${({ theme }) => theme.colors.midleBlack};


@media screen and (max-width: 436px) {
line-height:1.2rem;
}
`;

export const ButtonHero = styled(motion.a)`
display:inline-block ;
background-color: #6aa940;
padding: 15px 50px;
border: none;
/*box-shadow: 8px 6px 4px 5px rgba( 0, 0, 0, 0.25);  */
color: #fff;
text-decoration: none;
font-weight: ${({ theme }) => theme.fontWeight.fontWeightMedium};
font-size: clamp(1.1rem, calc(1.1rem + ((1vw - 0.36rem) * 0.3571)), 1.3rem);
min-height: 0vw;
text-align:center ;


:hover {
background:${({ theme }) => theme.colors.lightGreenHover};
transition: background-color 550ms ease-in-out;
-webkit-box-shadow: 15px 13px 23px -12px rgba(66, 68, 90, 1);
-moz-box-shadow: 15px 13px 23px -12px rgba(66, 68, 90, 1);
box-shadow: 15px 13px 23px -12px rgba(66, 68, 90, 1);
}

@media screen and (max-width: 768px) {
  width:100%;
}
@media screen and (max-width: 436px) {
width:100% ;
}
`;

export const ButtonMotion = styled(motion.div)`
width: 100%;
`;

export const RightHero = styled.div`
width: 50%;

@media screen and (max-width: 768px) {
width: 75%;
padding-bottom: 40px;
padding-top:20px;



/*  display: none;
img{
  display: none ;
}*/
}

@media screen and (max-width: 1024px) {
  width:100% ;
 /* height: 100%;*/
  }
  `;


